<template>
  <div class="preview-wrapper">
    <DgLayoutMain
      :logoFull="logo"
      position="initial"
      :style="style"
      :searchable="false"
      :expand="false"
    >
      <DgLayoutCommon
        :show-card="false"
        iconTitle="fas fa-calendar-alt"
        :title="title"
      >
        <grid-layout
          :layout.sync="layout"
          v-bind="{
            ...this.configGrid,
          }"
          :isMirrored="false"
          :responsive="false"
          verticalCompact
          :preventCollision="false"
          use-css-transforms
        >
          <template v-for="widget in layout">
            <grid-item
              v-if="!isWidgetEmpty(widget)"
              :key="widget.i"
              :x="widget.x"
              :y="widget.y"
              :w="widget.w"
              :h="widget.h"
              :i="widget.i"
              :type="widget.type"
              :isResizable="false"
              :isDraggable="false"
            >
              <HomeRenderWidget
                :widget="widget.item"
                :loading="widget.loading"
                :report-settings="reportSettings"
              />
            </grid-item>
          </template>
        </grid-layout>
      </DgLayoutCommon>
    </DgLayoutMain>
  </div>
</template>
<script>
import { GridLayout, GridItem } from "vue-grid-layout";
import { mapMutations, mapState, mapActions } from "vuex";
import HomeRenderWidget from "@/components/Home/RenderWidget";
import { GRID_CONFIGS } from "@/constants/gridConfigs";
import { DEFAULT_LANGUAGE } from "@/constants/language";
import { MUTATIONS } from "@/constants/vuex";
import { ACTIONS } from "@/constants/vuex";
import MixinFixWidget from "@/mixins/fixWidget";
import MixinUpdateWidgets from "@/mixins/updateWidgets";
import { getDashboardExport } from "@/service/dashboard";
import { verifyLanguage } from "@/utils/verifyWidgetLanguage";
import { DgLayoutCommon, DgLayoutMain } from "@engenharia/dashgoo-ui";
import { isEmpty, MixinEmptyWidget } from "@engenharia/dashgoo-widgets";

export default {
  name: "Preview",
  mixins: [MixinEmptyWidget, MixinFixWidget, MixinUpdateWidgets],
  components: {
    DgLayoutMain,
    DgLayoutCommon,
    HomeRenderWidget,
    GridLayout,
    GridItem,
  },
  data: () => ({
    logo: null,
    style: null,
  }),
  computed: {
    ...mapState("dashboard", ["layout", "period", "dashboardLanguage"]),
    ...mapState("user", ["logged"]),
    configGrid() {
      return GRID_CONFIGS;
    },
    title() {
      return `${this.$t("PERIOD")}: ${this.period}`;
    },
    language() {
      return this.dashboardLanguage || DEFAULT_LANGUAGE;
    },
    reportSettings() {
      const { settings = {} } = this.logged?.subscriber || {};

      return settings;
    },
  },
  methods: {
    ...mapMutations({
      setDashboard: `dashboard/${MUTATIONS.DASHBOARD.SET_DASHBOARD}`,
    }),
    ...mapActions({
      getDashboard: `dashboard/${ACTIONS.DASHBOARD.GET_DASHBOARD}`,
    }),
    isWidgetEmpty(widget) {
      return isEmpty(widget.item, widget.item.type);
    },
    verifyWidget(widget) {
      const verifiedWidget = verifyLanguage(widget, this.language);
      return verifiedWidget;
    },
  },
  async mounted() {
    const dashboard = await getDashboardExport(this.$route.params.token);
    const portalSettings = dashboard.client?.subscriber?.settings?.portal || {};
    const logo = `/uploads/portals/${dashboard.client?.subscriber?.id}.png`;
    this.setDashboard(dashboard);
    this.logo = logo;
    this.style = {
      "background-color": `${portalSettings.portalColor} !important`,
    };
  },
};
</script>

<style lang="scss" scoped>
.preview-wrapper {
  position: relative;
}
</style>

<style>
.dg-layout-common-header {
  padding-left: 15px;
}

.dg-header {
  background: #fff !important;
}

/* Specific style for emojis based on Twemoji */
img.emoji {
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.05em 0 0.1em !important;
  vertical-align: -0.1em !important;
  border-radius: initial !important;
  padding: 0 !important;
}
</style>
